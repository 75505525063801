import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy"/>
    <div class="container">
    <h1 class="display-3 pt-4">Privacy Policy</h1>

    <h2 class="display-6 pt-4">
      What information do we collect?
    </h2>
    <p>
      We only collect information from you when you fill out our contact form.</p>
    <p>When filling out the contact form, you may be asked to enter your: name, e-mail address and
      phone number. You may, however, visit our site anonymously.
    </p>
    <hr class="featurette-divider" />
    <h2 class="display-6">
      What do we use your information for?
    </h2>
    <p>
      Any of the information we collect from you may be used in the following ways:
    </p>
    <p><em>
      • To contact you following completion of the contact form.
    </em>
      Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company
      for any reason whatsoever, without your consent, other than for the express purpose of delivery of consulting services
      requested and agreed upon by you and Sorensen Solutions.
    </p>
    <p>
      <em>Note: If at any time you would like to unsubscribe from receiving future emails email us at <a href="mailto:questions@sorensen.solutions">
        questions@sorensen.solutions
      </a> with the subject "unsubscribe". </em>
    </p>
    <hr class="featurette-divider" />
    <h2 class="display-6">
      How do we protect your information?
    </h2>
    <p>
      We implement a variety of security measures to maintain the safety of your personal information when you enter,
      submit, or access your personal information. This includes, but is not limited to, industry standard encryption
      at-rest and in-transit.
    </p>
    <hr class="featurette-divider" />
    <h2 class="display-6">
      Do we use cookies?
    </h2>
    <p>
      No, cookies are not used on this website.
    </p>
    <hr class="featurette-divider" />
    <h2 class="display-6">
      Do we disclose any information to outside parties?
    </h2>
    <p>
      We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does
      not include trusted third parties who assist us in operating our website, conducting our business, or servicing you,
      so long as those parties agree to keep this information confidential. We may also release your information when we
      believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights,
      property, or safety.
    </p>
    <hr class="featurette-divider" />
    <h2 class="display-6">
      Online Privacy Policy Only
    </h2>
    <p>
      This online privacy policy applies only to information collected through our website and not to information
      collected offline.
    </p>
    <hr class="featurette-divider" />
    <h2 class="display-6">
      Your Consent
    </h2>
    <p>
      By using our site, you consent to our websites privacy policy.
    </p>
    <hr class="featurette-divider" />
    <h2 class="display-6">
      Changes to our Privacy Policy
    </h2>
    <p>
      If we decide to change our privacy policy, we will post those changes on this page.
    </p>
    <hr class="featurette-divider" />
    <h2 class="display-6">
      Contacting Us
    </h2>
    <p>
      If there are any questions regarding this privacy policy you may contact us at
      &nbsp;<a href="mailto:questions@sorensen.solutions">questions@sorensen.solutions</a>
    </p>
    <hr class="featurette-divider"/>
  </div>
  </Layout>
)

export default PrivacyPage